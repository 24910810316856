<template>
  <one-column>
    <template #content>
      <router-link :to="{name: 'dashboard'}">{{ $t('links.toDashboard.label') }}</router-link>
    </template>
  </one-column>
</template>

<script>
import { appSettings } from '@/settings'
import { ios, android } from 'platform-detect'
import OneColumn from '@/layouts/OneColumn.vue'

export default {
  name: 'HearCare',

  components: {
    OneColumn
  },

  metaInfo () {
    return {
      title: 'HearCare'
    }
  },

  mounted () {
    const appStore = appSettings.services.hearcare.ios
    const playStore = appSettings.services.hearcare.android
    const fallback = appSettings.services.hearcare.fallback
    if (ios) {
      window.location.assign(appStore)
    } else if (android) {
      window.location.assign(playStore)
    } else {
      window.location.assign(fallback)
    }
  }
}
</script>
